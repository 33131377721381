















import { Component, Prop, Provide, Vue } from 'vue-property-decorator'
import VappAPI from '@/api/vapp'
import { workApp } from '@/utils'
import callApp from '@/services/sdk'

@Component({
  components: {},
  filters:{}
})
export default class DcpList extends Vue {

  /// props
  @Prop({ default: '' }) public basePath!: string

  /// data
  public sceneData: Array<any> = []

  /// provide data
  @Provide() provideData: Object = {}

  // computed
  public get infos(): Array<any> {
    return Array<any>()
  }
  public get isWorkApp(): Boolean {
    return workApp
  }

  public dcpOperationDialogVisible: boolean = false
  public selectedDCP: any = {}
  public currentProject: any = {}

  public dcpDataList(item: any) {
    this.dcpOperationDialogVisible = false
    let applyno = this.$route.query.applyno
    let token = this.$route.query.token
    let sceneInfoList = JSON.stringify(item.sceneInfoList)
    let sceneGroup = item.sceneGroup
    this.$router.push({name: "dcpdatalist", query: {scene: item.scene, applyno: applyno, token, sceneDcpId: item.sceneDcpId, sceneGroup, sceneInfoList}})
  }

  public popBack() {
    this.$router.back()
  }

  mounted() {
    let applyno = this.$route.query.applyno
    VappAPI.sceneList({headers: { applyno: applyno, language: 'zh-CN' }}).then(data=> {
      this.sceneData = data
    }).catch(error => {

    })
    let pagination = {page: 1, size: 10, startTime: "", endTime: "", projectName: "", projectStatus: "", needPage: true}
    VappAPI.projectList(pagination).then(data => {
        data.list && data.list.forEach((project: any)=>{
          if(project.applyNo == applyno){
            this.currentProject = project
            let projectName = this.currentProject && this.currentProject.projectName
            callApp("setNavBarTitle",{
                      type: "navbar.updateNavBarTitle",
                      data: {
                        key: 'title',
                        val: projectName
                      }
                    }, "cbSetNavBarTitle", ()=>{}, undefined);
          }
        })
    }).catch(error => {

    })
  }

}
