import axios from 'axios'
import i18n from '@/assets/lang/index'
import { Message, Loading } from 'element-ui'
import { ElLoadingComponent } from 'element-ui/types/loading'
import { isObject } from '@/utils'
import { commonStoreModule } from '@/store/module/common'

let errorObject = i18n.messages['en'].error;
let errCode = Object.keys(errorObject);

const service = axios.create({
  baseURL: window.Config.base_url, // api 的 base_url
  timeout: 60000 // request timeout
})

let loading: ElLoadingComponent // 定义loading变量

function startLoading() {
  // 使用Element loading-start 方法
  loading = Loading.service({
    lock: true,
    text: 'Loading...',
    background: 'rgba(0, 0, 0, 0.7)'
  })
}

function endLoading() {
  // 使用Element loading-close 方法
  loading && loading.close()
}

// 请求拦截  设置统一header
service.interceptors.request.use(
  config => {
    let {headers, ignoreLoading , ...data} = config.data
    config.headers.applyno = headers && headers.applyno || undefined
    if(headers && headers['x-api-token']){
      config.headers['x-api-token'] = headers && headers['x-api-token']
    }
    if (commonStoreModule.token.length) {
      config.headers['x-api-token'] = commonStoreModule.token
    }
    if (config.method == 'post') {

      let isFormdata = data && data instanceof FormData
      if(!isFormdata){
        config.data = {
          ...data,
          _t: Date.now()
        }
      }

      if(!ignoreLoading){
        startLoading()
      }

    } else if (config.method == 'get') {
      config.params = {
        _t: Date.now(),
        ...config.params
      }

      if(!ignoreLoading){
        startLoading()
      }
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// 响应拦截  401 token过期处理
service.interceptors.response.use(
  res => {
    endLoading()

    let url = res.config.url;
    if (url && url.includes("resource")) {
      // 兼容文件资源查看
      return res;
    }

    let result = res.data;
    result.code = String(result.code);

    res.data = isObject(res.data) ? res.data : { code: "common.success", data: res.data };

    //success
    if (["common.success", "1", "0"].includes(String(result.code))) {
      return result.data;
    }

    let _errCode = result.code.split(".")[2] || result.code;
    let reject = {
      ...result.data,
      errMsg: result.message || _errCode
    };

    let langErrMsg = errCode.filter(key => key === result.code)[0];
    if (langErrMsg) {
      let localeMessages: any = i18n.t('error');
      _errCode = localeMessages[langErrMsg];
    }

    let userInfoUrl = url && url.includes("userInfo")
    // token invalid or org disable
    if (['tc.common.LoginTokenInvalidException', 'common.LoginTokenInvalidException'].includes(String(result.code)) && !userInfoUrl) {
      console.log("gotoLogin")

    } else if (!['tc.common.LoginTokenInvalidException', 'common.LoginTokenInvalidException'].includes(String(result.code)) && !userInfoUrl) {
      /// 放开 user/apply
      let whiteListUrl = (url && url.includes("/user/apply")) || (url && url.includes('/user/update'))
      if(['eco.AccountContentRuleException',
          'eco.NormalContentRuleException',
          'common.RequestParamsEmptyException',
          'common.RequestParamsInvalidException',
          'common.DataExistedException'].includes(String(result.code)) && whiteListUrl){
        return result.data;
      }

      Message.error(_errCode);
    }

    return Promise.reject(reject);
  },
  error => {
    // 错误提醒
    endLoading()
    Message.error(error.response.data)
    const { status } = error.response
    if (status === 401) {
      Message.error('token值无效,请重新登录')
    }
    return Promise.reject(error)
  }
)

export default service
