
import axios from '@/utils/request'

class VAPPAPI {
  // 项目列表
  projectList(data: Object): Promise<any> {
    return axios.request({
      url: `/operatorapi/service/serviceInstanceProjectList`,
      method: 'post',
      data: data
    })
  }
  // dcp 列表  拿到dcp名字显示标题
  sceneList(data: any): Promise<any> {
    let headers = data.headers || {}
    return axios.request({
      url: `/supplychainapi/v1/businessScene/getBusinessSceneGroupList`,
      method: 'post',
      data: data,
      headers
    })
  }

  // 拉取动态表单模版，也叫品类模版
  sceneDetail(data: any): Promise<any> {
    let headers = data.headers || {}

    return axios.request({
      url: `/supplychainapi/v1/dcpModel/getDcpModelDetail` + '?scene=' + data.scene,
      method: 'post',
      data: data,
      headers
    })
  }

  /// record detail  dcp 数据记录详情
  recordDetail(data: any): Promise<any> {
    let { scene } = data
    if(scene == 'SKU'){
      return this.skuRecordDetail(data)
    } else if (scene == 'PRODUCT_MANAGEMENT') {
      return this.bindskuRecordDetail(data)
    } else if (scene == 'LOGISTIS_SEND') {
      return this.sendRecordDetail(data)
    } else if (scene == 'LOGISTIS_RECEIVE') {
      return this.receiveRecordDetail(data)
    } else {
      return this.businessDataDetail(data)
    }
  }

  /// sku record
  skuRecordDetail(data: any): Promise<any> {
    let headers = data.headers || {}

    return axios.request({
      url: `/supplychainapi/sku/getSkuDetail?bizDataId=` + data.bizDataId + '&suborgUuid=' + data.suborgUuid,
      method: 'post',
      data: data,
      headers
    })
  }

  /// bindsku record
  bindskuRecordDetail(data: any): Promise<any> {
    let headers = data.headers || {}

    return axios.request({
      url: `/supplychainapi/v1/skuBind/getSkuBindDetail?bizDataId=` + data.bizDataId,
      method: 'post',
      data: data,
      headers
    })
  }

  /// send record
  sendRecordDetail(data: any): Promise<any> {
    let headers = data.headers || {}

    return axios.request({
      url: `/supplychainapi/v1/asnOrder/getSendDetail?orderNo=` + data.orderNo,
      method: 'post',
      data: data,
      headers
    })
  }

  /// receive record
  receiveRecordDetail(data: any): Promise<any> {
  let headers = data.headers || {}

    return axios.request({
      url: `/supplychainapi/v1/asnOrder/getReceiveDetail?orderNo=` + data.orderNo,
      method: 'post',
      data: data,
      headers
    })
  }

  /// business record
  businessDataDetail(data: any): Promise<any> {
    let headers = data.headers || {}

    return axios.request({
      url: `/supplychainapi/v1/businessData/getBusinessDataDetail`,
      method: 'post',
      data: data,
      headers
    })
  }

}


export default new VAPPAPI()
